<template>
  <div class="score-wrap">
    <div class="score-body">
      <div class="score-body_card">
        <div class="card-title">{{ growthPointsShown }}</div>
        <div class="card-content">
          {{ $t('member.score.tab') }} <a-popover :content="$t('member.score.tip')" trigger="hover" placement="bottom">
          <span class="icon-tip" />
        </a-popover>
        </div>
      </div>
      <div class="score-body_table">
        <ECTable
          :scroll="{ y: 266 }"
          :columns="scoreColumns"
          :data-source="scoreList"
          :pagination="{ current, total, pageSize }"
          @change="handleTableChange"
          :loading="loading"
        ></ECTable>
      </div>
    </div>
    <div class="score-bottom">
      <div class="score-bottom_title">
        {{ $t('member.score.useRecord') }}
      </div>
      <div class="score-bottom_list">
        <template v-if="useList.length">
        <div class="score-bottom_item" v-for="i in useList" :key="i.id">
          <div class="item-img"></div>
          <div class="item-content">
            <span class="item-title">{{ i.source }}</span>成长积分
          </div>
          <div class="item-time">{{ i.createTime }}</div>
        </div>
        </template>
        <template v-else>
          <div> {{ $t('member.score.noneUseRecord') }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { getPointList, getPointUseList } from "./api";
import { FETCH_CODE, PRICE_DECIMALS, PRICE_RATE_FRONT } from "@/config";
import BigNumber from "bignumber.js";
import { mapState } from "vuex";
import { showMsg } from "@/utils";

export default {
  name: "Points",
  components: {
    ECTable
  },
  data() {
    return {
      scoreColumns: [
        {
          dataIndex: "createTime",
          align: "center",
          title: this.$t("member.score.table.time")
        },
        {
          dataIndex: "sourceName",
          align: "center",
          title: this.$t("member.score.table.source")
        },
        {
          dataIndex: "showPoints",
          align: "center",
          title: this.$t("member.score.table.number")
        },
        {
          dataIndex: "4",
          align: "center",
          title: this.$t("member.score.table.rest")
        }
      ],
      scoreList: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      useList: [],
    };
  },
  computed: {
    ...mapState({
      growthPointsShown: state => state.user.growthPoints.shown,
    }),
  },
  methods: {
    async getList() {
      try {
        if (this.loading) {
          return;
        }
        this.loading = true;
        const res = await getPointList({
          pageSize: this.pageSize,
          pageNum: this.current
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        const scoreList = res.data.records || [];
        this.scoreList = scoreList.map((it) => {
          return {
            ...it,
            showPoints: BigNumber(it.points || 0).times(PRICE_RATE_FRONT).toFixed(PRICE_DECIMALS),
          };
        });
        this.current = res.data.current;
        this.total = res.data.total;
        this.pageSize = res.data.size;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getRecordList() {
      try {
        const res = await getPointUseList({ num: 1000 });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        const useList = res?.data?.records || []
        this.useList = useList.map((it) => {
          return {
            ...it,
            showPoints: BigNumber(it.points || 0).times(PRICE_RATE_FRONT).toFixed(PRICE_DECIMALS),
          };
        });
      } catch(e) {
        console.error(e)
      }
    },
    async handleTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      await this.getList();
    }
  },
  mounted() {
    this.getList();
    this.getRecordList();
  }
};
</script>

<style lang="less" scoped>
.score-wrap {
  .score-body {
    display: flex;
    gap: 20px;
    height: 380px;
    &_card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 300px;
      background: #ffffff;
      border-radius: 8px;
      border: 1px solid #e9ebfb;
      flex-shrink: 0;
      .card-title {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .card-content {
        color: @sub-color;
        span {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    &_table {
      width: 100%;
      flex-grow: 1;
      height: 100%;
      /deep/.table-card {
        min-height: 100%;
      }
    }
  }
  .score-bottom {
    &_title {
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
    }
    &_list {
      display: flex;
      gap: 20px;
      .score-bottom_item {
        text-align: center;
        .item-img {
          width: 235px;
          height: 208px;
          background: #f7f7f7;
        }
        .item-content {
          margin: 10px 0 8px 0;
          .item-title {
            font-weight: bold;
          }
        }
        .item-time {
          color: @tip-color;
        }
      }
    }
  }
}
</style>
