<template>
  <div class="base-form" :class="lang">
    <a-form-model
      layout="inline"
      labelAlign="right"
      ref="form"
      :colon="false"
      :model="form"
      :labelCol="formItemLayout.labelCol"
      :wrapperCol="formItemLayout.wrapperCol"
    >
      <a-row :gutter="[24, 24]">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.enterName')"
          >
            {{ form.currentCompany.name }}
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.name')"
            prop="name"
          >
            <span>{{ form.name }}</span>
            <!--<a-input
              type="text"
              :placeholder="$t('member.base.form.name')"
              v-model="form.name"
            />-->
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24, 24]">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.shortName')"
          >
            {{ form.currentCompany.nameShort }}
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.mobile')"
            prop="mobilePhoneNumber"
          >
            <span>{{ form.mobilePhoneCode }}&nbsp;</span>
            <span>{{ form.mobilePhoneNumber }}</span>
            <!--<a-input
              type="text"
              :maxLength="11"
              :placeholder="$t('member.base.form.mobile')"
              v-model="form.mobilePhoneNumber"
            />-->
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24, 24]">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.trade')"
          >
            {{ form.currentCompany.industryId || "--" }}
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.email')"
            prop="emailAddress"
          >
            <span>{{ form.emailAddress }}</span>
            <!--<a-input
              type="text"
              v-model="form.emailAddress"
              :placeholder="$t('member.base.form.email')"
            ></a-input>-->
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24, 24]">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.city')"
          >
            <template
              v-if="form.currentCompany.province || form.currentCompany.city"
            >
              {{ form.currentCompany.province }}{{ form.currentCompany.city }}
            </template>
            <template v-else>--</template>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.mainAcct')"
          >
            {{ form.master ? labelFormatter(YES_OR_NO[0]) : labelFormatter(YES_OR_NO[1]) }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="[24, 24]">
        <a-col :span="12">
          <a-form-model-item
            :label="$t('member.base.form.avatar')"
          >
            <div
              class="avatar"
              :style="{
                backgroundImage: 'url(' + (avatarUrl || form.headImg) + ')'
              }"
            />
            <a-button
              ghost
              type="primary"
              shape="round"
              size="small"
              @click="changeAvatar"
            >
              {{ $t("member.base.editAvatar") }}
            </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="button-group">
      <a-button type="primary" @click="changePsd">
        {{ $t("member.base.editPsd") }}
      </a-button>
      <!--<a-button type="primary" @click="save">
        {{ $t("button.save") }}
      </a-button>-->
    </div>
    <!--  头像弹窗  -->
    <div v-if="isShowModal">
      <a-modal
        :maskClosable="false"
        :footer="null"
        v-model="isShowModal"
        :title="$t('menu.person.avatar')"
        :width="700"
        :destroyOnClose="true"
      >
        <div class="avatar-modal">
          <div class="avatar-item">
            <div
              class="avatar-block"
              :style="{
                backgroundImage: `url(${avatarBackUrl || form.headImg})`
              }"
            />
            <!--            <template v-if="cropperUrl">-->
            <!--              <VueCropper-->
            <!--                ref="cropper"-->
            <!--                :img="cropperBaseUrl"-->
            <!--                :outputSize="options.size"-->
            <!--                :outputType="options.outputType"-->
            <!--                :info="options.info"-->
            <!--                :canScale="options.canScale"-->
            <!--                :autoCrop="options.autoCrop"-->
            <!--                :autoCropWidth="options.autoCropWidth"-->
            <!--                :autoCropHeight="options.autoCropHeight"-->
            <!--                :fixed="options.fixed"-->
            <!--                :fixedNumber="options.fixedNumber"-->
            <!--                :enlarge="4"-->
            <!--              ></VueCropper>-->
            <!--            </template>-->
            <!--            </div>-->
            <EcAvatarUpload
              :file-list="fileList"
              @change="getFileList"
              :show-upload-list="false"
            >
              <a-button slot="upload" class="avatar-btn">
                {{ $t("member.base.uploadBtn") }}
              </a-button>
            </EcAvatarUpload>
          </div>
          <div class="avatar-item">
            <div class="preview-flex">
              <div class="avatar-title">{{ $t("member.base.preview") }}</div>
              <div
                class="avatar-preview"
                :style="{
                  backgroundImage: `url(${avatarBackUrl || form.headImg})`
                }"
              />
            </div>
            <a-button type="primary" @click="confirmAvatar">
              {{ $t("member.base.submitBtn") }}
            </a-button>
          </div>
        </div>
      </a-modal>
    </div>
    <!--  密码弹窗  -->
    <div v-if="isPsdModal">
      <a-modal
        :width="lang === langList[0].key ? 650 : 520"
        :maskClosable="false"
        :destroyOnClose="true"
        v-model="isPsdModal"
        :title="$t('member.base.editPsd')"
        @ok="confirmPsd"
        @cancel="cancelPsd"
      >
        <div class="psd-modal">
          <div class="psd-modal_head">
            <div
              :class="[
                currentPsdTab === 1 ? 'head-item head-item_active' : 'head-item'
              ]"
              @click="changePsdTab(1)"
            >
              {{ $t("member.base.psd.loginPsd") }}
            </div>
            <div
              :class="[
                currentPsdTab === 2 ? 'head-item head-item_active' : 'head-item'
              ]"
              @click="changePsdTab(2)"
            >
              {{ $t("member.base.psd.payPsd") }}
            </div>
          </div>
          <div class="psd-modal_body">
            <!-- 支付密码弹窗 -->
            <div v-if="currentPsdTab === 2">
              <a-form-model
                :rules="payRules"
                :model="payForm"
                ref="payForm"
                :labelCol="calcFormItemLayout([8]).labelCol"
                :wrapperCol="calcFormItemLayout([8]).wrapperCol"
                labelAlign="right"
                :colon="false"
              >
                <a-form-model-item
                  :label="$t('member.base.psd.currentPay')"
                  prop="oldPsd"
                >
                  <a-input
                    type="password"
                    v-model="payForm.oldPsd"
                    :placeholder="$t('member.base.psd.loginPsdPh')"
                  ></a-input>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('member.base.psd.newPay')"
                  prop="newPsd"
                >
                  <a-input
                    type="password"
                    v-model="payForm.newPsd"
                    :placeholder="newPasswordPlaceholder"
                  ></a-input>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('member.base.psd.confirmPay')"
                  prop="confirmPsd"
                >
                  <a-input
                    type="password"
                    v-model="payForm.confirmPsd"
                    :placeholder="newPasswordPlaceholder"
                  ></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
            <!-- 登录密码弹窗 -->
            <div v-if="currentPsdTab === 1">
              <a-form-model
                :rules="loginRules"
                :model="loginForm"
                ref="loginForm"
                :labelCol="calcFormItemLayout([7]).labelCol"
                :wrapperCol="calcFormItemLayout([7]).wrapperCol"
                labelAlign="right"
                :colon="false"
              >
                <a-form-model-item
                  :label="$t('member.base.psd.currentLogin')"
                  prop="oldPsd"
                >
                  <a-input
                    type="password"
                    v-model="loginForm.oldPsd"
                    :placeholder="$t('member.base.psd.loginPsdPh')"
                  ></a-input>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('member.base.psd.newLogin')"
                  prop="newPsd"
                >
                  <a-input
                    type="password"
                    v-model="loginForm.newPsd"
                    :placeholder="newPasswordPlaceholder"
                  ></a-input>
                </a-form-model-item>
                <a-form-model-item
                  :label="$t('member.base.psd.confirmLogin')"
                  prop="confirmPsd"
                >
                  <a-input
                    type="password"
                    v-model="loginForm.confirmPsd"
                    :placeholder="newPasswordPlaceholder"
                  ></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  updateHR,
  getUsrInfo,
  updatePayPwd,
  updatePassword,
  checkPayPwd
} from "./api";
import { clearCookies, FETCH_CODE, REG_EMAIL } from "@/config";
import EcAvatarUpload from "@/components/EcAvatarUpload.vue";
import defaultAvatar from "@/assets/images/home_avatar.png";
import { cloneDeep } from "lodash";
import { showMsg } from "@/utils";
import { langMixin } from "@/mixins";
import { YES_OR_NO } from "@/pages/workspace/backgroundCheck/order/config";
import { rules as PasswordRules} from '@/config/custom/password-rule';

export default {
  name: "BaseInfo",
  components: {
    EcAvatarUpload
    // VueCropper
  },
  mixins: [langMixin],
  computed: {
    cropperBaseUrl() {
      console.log(this.getImage2Base64());
      return this.cropperUrl || this.getImage2Base64();
    },
    formItemLayout() {
      return this.calcFormItemLayout([8, 11]);
    }
  },
  data() {
    return {
      YES_OR_NO,
      rules: {
        name: [
          {
            required: true,
            message: this.$t("input") + this.$t("member.base.form.name"),
            trigger: "blur"
          }
        ],
        mobilePhoneNumber: [
          {
            required: true,
            // eslint-disable-next-line
            pattern: /[+\(\d]{3,4}[\(\d \)-]{4,8}[\d]{4}/g,
            trigger: "blur",
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("member.base.form.mobile")
          }
        ],
        emailAddress: [
          {
            required: true,
            pattern: REG_EMAIL,
            trigger: "blur",
            message:
              this.$t("input") +
              this.$t("right") +
              this.$t("member.base.form.email")
          }
        ]
      },
      isShowModal: false,
      form: {
        name: "",
        emailAddress: "",
        mobilePhoneNumber: "",
        currentCompany: {}
      },
      formBack: {},
      fileList: [],
      avatarUrl: "",
      avatarBackUrl: "",
      isPsdModal: false,
      options: {
        info: true,
        size: 1,
        outputType: "jpeg",
        canScale: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 300,
        autoCropHeight: 250,
        fixed: true,
        // 真实的输出宽高
        infoTrue: true
        // fixedNumber: [4, 3]
      },
      cropperUrl: "",
      currentPsdTab: 1,
      payForm: {
        confirmPsd: "",
        newPsd: "",
        oldPsd: ""
      },
      payRules: {
        oldPsd: [
          {
            required: true,
            message: this.$t("member.base.psd.loginPsdPh"),
            trigger: "blur"
          }
        ],
        newPsd: [
          {
            min: 6,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            required: true,
            message: this.$t("user.resetPwd.tips"),
            trigger: "blur"
          }
        ],
        confirmPsd: [
          {
            min: 6,
            required: true,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            message: this.$t("user.resetPwd.tips"),
            trigger: "blur"
          }
        ]
      },
      accountPasswordRule: PasswordRules[this.$store.state.user.info.currentCompany.companyId] || PasswordRules[''],
      loginRules: {
        oldPsd: [
          {
            required: true,
            message: this.$t("member.base.psd.loginPsdPh"),
            trigger: "blur"
          }
        ],
        newPsd: [
          {
            min: 6,
            required: true,
            message: this.$t("user.resetPwd.tips"),
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            trigger: "blur"
          }
        ],
        confirmPsd: [
          {
            min: 6,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/,
            required: true,
            message: this.$t("user.resetPwd.tips"),
            trigger: "blur"
          }
        ]
      },
      loginForm: {
        confirmPsd: "",
        newPsd: "",
        oldPsd: ""
      },
      isUploading: false,
      fileListBack: [],
      newPasswordPlaceholder: ''
    };
  },
  methods: {
    changeAvatar() {
      this.isShowModal = !this.isShowModal;
    },
    confirmAvatar() {
      this.avatarUrl = this.avatarBackUrl;
      this.isShowModal = false;
    },
    changePsd() {
      this.isPsdModal = true;
    },
    save() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.updateInfo();
        }
      });
    },
    async updateInfo() {
      try {
        const {
          emailAddress,
          name,
          mobilePhoneNumber,
          master,
          userId,
          headImg
        } = this.form;
        let params = {
          email: emailAddress,
          name,
          phone: mobilePhoneNumber,
          userId,
          headImg: this.avatarUrl || headImg,
          master: master ? 1 : 0
        };
        const res = await updateHR(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          this.form = this.formBack;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: `${this.$t("member.base.tab")}${this.$t("showMsg.success.save")}`
        });
        await this.$store.dispatch("user/getUserInfo");
        this.getUserInfo();
      } catch (e) {
        console.error(e);
      }
    },
    input(e) {
      console.log(e);
      this.form.name = e.detail.value;
    },
    getFileList(file) {
      this.avatarBackUrl = file[0]?.response?.data || "";
      this.fileList = file;
      // const reader = new FileReader();
      // // 转化为base64
      // reader.readAsDataURL(file[0].originFileObj);
      // reader.onload = () => {
      //   // 获取到需要剪裁的图片 展示到剪裁框中
      //   this.cropperUrl = reader.result;
      // };
    },
    confirmPsd() {
      if (this.currentPsdTab === 1) {
        this.$refs.loginForm.validate(async valid => {
          if (valid) {
            if (this.loginForm.newPsd !== this.loginForm.confirmPsd) {
              this.$message.warning(this.$t("member.base.psd.tips"));
              return;
            }
            await this.updatePwd();
          }
        });
      } else {
        this.$refs.payForm.validate(async valid => {
          if (valid) {
            if (this.payForm.newPsd !== this.payForm.confirmPsd) {
              this.$message.warning(this.$t("member.base.psd.tips"));
              return;
            }
            await this.checkPwd();
            await this.changePayPwd();
          }
        });
      }
    },
    cancelPsd() {
      this.isPsdModal = false;
    },
    async updatePwd() {
      try {
        const params = {
          newPassword: this.loginForm.newPsd,
          oldPassword: this.loginForm.oldPsd
        };
        const res = await updatePassword(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: `${this.$t("member.base.psd.loginPsd")}${this.$t(
            "showMsg.success.change"
          )}`
        });
        this.loginForm = {};
        this.isPsdModal = false;
        clearCookies();
        this.$store.dispatch("user/clearUserInfo");
        this.$store.dispatch("home/clearHomeInfo");
        this.$router.replace("/user/sign-in");
      } catch (e) {
        console.error(e);
      }
    },
    async changePayPwd() {
      try {
        const params = {
          newPassword: this.payForm.newPsd,
          oldPassword: this.payForm.oldPsd
        };
        const res = await updatePayPwd(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: `${this.$t("member.base.psd.payPsd")}${this.$t(
            "showMsg.success.change"
          )}`
        });
        this.isPsdModal = false;
        this.payForm = {};
      } catch (e) {
        console.error(e);
      }
    },
    async checkPwd() {
      try {
        const res = await checkPayPwd();
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
          return;
        }
      } catch (e) {
        console.error(e);
      }
    },
    getUserInfo() {
      getUsrInfo()
        .then(res => {
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            return;
          }
          this.form = res.data;
          if (!this.form.headImg) {
            this.form.headImg = defaultAvatar;
          }
          this.fileList = [
            {
              uid: "-1",
              name: "headImg",
              status: "done",
              url: this.form.headImg
            }
          ];
          this.fileListBack = cloneDeep(this.fileList);
          this.form.currentCompany = this.form.currentCompany || {};
          this.formBack = cloneDeep(this.form);
        })
        .catch(e => {
          console.error(e);
        });
    },
    changePsdTab(tab) {
      this.currentPsdTab = tab;
    },
    image2Base64(img) {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      return canvas.toDataURL("image/png");
    },
    getImage2Base64() {
      let image = new Image();
      image.src = require("../../assets/images/home_avatar.png");
      // image.onload = () => {
      return this.image2Base64(image);
      // }
      // console.log(base64)
      // return base64
    },
    iniPasswordRule() {
      this.loginRules.newPsd[0].min = this.accountPasswordRule.min;
      this.loginRules.confirmPsd[0].min = this.accountPasswordRule.min;

      this.loginRules.newPsd[0].pattern = this.accountPasswordRule.pattern;
      this.loginRules.confirmPsd[0].pattern = this.accountPasswordRule.pattern;
      
      const typeRequirement = [];

      if (this.accountPasswordRule.requireNumber) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeNumber'));
      }

      if (this.accountPasswordRule.requireLetter) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeLetter'));
      }

      if (this.accountPasswordRule.requireSymbol) {
        typeRequirement.push(this.$t('user.resetPwd.requiredTypeSymbol'));
      }

      let tipMsg = this.$t('user.resetPwd.requiredLength', {length: this.accountPasswordRule.min});

      if (typeRequirement.length > 0) {
        tipMsg += `, ${this.$t('user.resetPwd.requiredType', {type: typeRequirement.toString()})}`;
      }

      if (this.accountPasswordRule.caseSensitive) {
        tipMsg += `, ${this.$t('user.resetPwd.caseSensitive')}`;
      }

      this.newPasswordPlaceholder = tipMsg;
      this.loginRules.newPsd[0].message = tipMsg;
      this.loginRules.confirmPsd[0].message = tipMsg;
    }
  },
  watch: {
    isShowModal(value) {
      if (!value) {
        this.fileList = this.fileListBack;
        this.avatarBackUrl = "";
      }
    }
  },
  mounted() {
    this.getUserInfo();
    this.iniPasswordRule();
  }
};
</script>

<style lang="less" scoped>
.base-form {
  padding: 40px 20% 0 20%;
  &.en {
    padding-left: 10%;
    padding-right: 10%;
  }
  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.button-group {
  margin-top: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 40px;
}
.avatar-modal {
  display: flex;
  gap: 100px;
  padding: 0 20px;
  .avatar-item {
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    flex-shrink: 0;
    button {
      width: 100%;
    }
    .avatar-block {
      width: 240px;
      height: 240px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .avatar-title {
      color: @sub-color;
    }
    .avatar-btn {
      background: @secondary-color;
      color: #fff;
      text-align: center;
      display: block;
      margin: 30px auto 0 auto;
    }
    .avatar-preview {
      width: 114px;
      height: 114px;
      border-radius: 50%;
      margin: 40px auto 0 auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .preview-flex {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
.psd-modal {
  &_head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    .head-item {
      color: @tip-color;
      cursor: pointer;
      &:first-child {
        border-radius: 8px 0 0 8px;
        border-right: none;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
      padding: 5px 24px;
      border: 1px solid #ccd8f0;
    }
    .head-item_active {
      color: #fff;
      background: #266fe8;
    }
  }
}
/deep/.ant-upload.ant-upload-select-picture-card {
  background: none !important;
  border: none !important;
  height: unset;
  margin: 40px 0 0 0;
  width: 100%;
}
/deep/.ant-upload.ant-upload-select-picture-card > .ant-upload {
  padding: 0 !important;
}
</style>
