<template>
  <div>
    <ECTable :columns="columns" :data-source="dataList" showTitle title=" " :pagination="{ total, current, pageSize }"  @change="handleTableChange" :loading="loading">
      <div slot="tableHeaderRight">
        <a-button type="primary" @click="add">{{ $t('member.acct.create') }}</a-button>
      </div>
      <template slot="isDisabled" slot-scope="text, record">
        <a-switch :checked="!record.isDisabled" :disabled="true" />
      </template>
      <template slot="squareAudit" slot-scope="text, record">
        <a-switch
          :checked="record.squareAudit"
          @change="(e) => onSquareAudit(e, record)"
        />
      </template>
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">
          {{ $t("button.edit") }}
        </a-button>
      </template>
    </ECTable>
    <!--  弹窗  -->
    <div v-if="isShowModal">
      <a-modal
          v-model="isShowModal"
          :maskClosable="false"
          :title="modalTitle"
          @ok="onModalOK"
          @cancel="onModalCancel"
      >
      <div class="modal-title color-primary">{{ $t('member.base.tab') }}</div>
      <UserEditForm
          ref="userFormRef"
          :form.sync="form"
          :is-edit="isEdit"
      />
    </a-modal>
    </div>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { addSubAcct, getSubAcct, squareAudit, updateSubAcct } from "./api"
import {FETCH_CODE} from "@/config";
import UserEditForm from "@/pages/HR/UserEditForm.vue";
import {cloneDeep} from "lodash";
import {showMsg} from "@/utils";
import {infoStaff} from "@/pages/HR/api";

const emptyForm = {
  name: null,
  userType: undefined,
  mobilePhoneCode: 86,
  mobilePhoneNumber: null,
  telephoneNumber: null,
  emailAddress: null,
  gender: null,
  birthday: null,
  induction: null,
  master: false,
  roleIds: undefined,
  headImg: null,
  companyId: undefined,
  deptId: undefined,
  activeStatus: undefined,
  isCurrent: false,
  joinGrantPoints: false,
  sendMsg: true,
};

export default {
  name: 'Sub',
  components: {
    UserEditForm,
    ECTable
  },
  data () {
    return {
      columns: [
        {
          dataIndex: 'name',
          align: 'center',
          title: this.$t("member.base.form.name")
        },
        {
          dataIndex: 'mobilePhoneNumber',
          align: 'center',
          title: this.$t("member.base.form.mobile")
        },
        {
          dataIndex: 'emailAddress',
          align: 'left',
          title: this.$t("member.base.form.email")
        },
        {
          dataIndex: 'roleName',
          align: 'center',
          title: this.$t("member.acct.auth")
        },
        {
          dataIndex: 'lastTime',
          align: 'center',
          title: this.$t("member.acct.time")
        },
        {
          key: 'squareAudit',
          align: 'center',
          title: this.$t("member.acct.squareAudit"),
          scopedSlots: { customRender: "squareAudit" },
        },
        {
          key: 'isDisabled',
          align: 'center',
          title: this.$t("member.acct.start"),
          scopedSlots: { customRender: "isDisabled" },
        },
        {
          key: 'action',
          align: 'center',
          title: this.$t("action"),
          scopedSlots: { customRender: "action" },
        },
      ],
      dataList: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      isShowModal: false,
      form: cloneDeep(emptyForm),
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      roleIds: '',
      isEdit: false,
    }
  },
  computed: {
    modalTitle () {
      return `${this.isEdit ? this.$t('button.edit') : this.$t('button.add')}${this.$t('member.acct.tab')}`
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async onEdit (item) {
      this.isEdit = true;
      this.form = {
        ...item,
        userType: item.type,
      };
      const res = await infoStaff({ userId: item.userId })
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res.msg)
        return
      }
      this.form.roleIds = res.data.roleIds || ""
      this.form.companyId = res.data.companyId
      this.isShowModal = true;
      this.$nextTick(() => {
        if (this.$refs.userFormRef) {
          this.$refs.userFormRef.init()
        }
      })
    },
    getList () {
      if (this.loading) {
        return
      }
      this.loading = true
      getSubAcct({
        pageNum: this.current,
        pageSize: this.pageSize
      }).then(res => {
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
        this.dataList = res.data.records || []
        this.current = res.data.current
        this.pageSize = res.data.size
        this.total = res.data.total
        this.loading = false
      }).catch((e) => {
        this.loading = false
        console.error(e)
      })
    },
    handleTableChange (page) {
      console.log(page)
      this.current = page.current
      this.pageSize = page.pageSize
      this.getList()
    },
    add() {
      this.isEdit = false;
      this.isShowModal = true;
    },
    onModalOK() {
      this.$refs.userFormRef.onValid().then(async (params) => {
        if (!params) return;
        this.isEdit ? await this.updateAcct(params) : await this.addAcct(params);
      });
    },
    onModalCancel () {
      this.form = cloneDeep(emptyForm);
      this.isShowModal = false;
    },
    async addAcct (params) {
      try {
        const res = await addSubAcct(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: `${this.$t('member.acct.tab')}${this.$t('showMsg.success.add')}`,
        });
        this.onModalCancel();
        this.current = 1;
        this.getList();
      } catch (e) {
        console.error(e)
      }

    },
    async updateAcct (params) {
      try {
        const res = await updateSubAcct(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: `${this.$t('member.acct.tab')}${this.$t('showMsg.success.change')}`,
        });
        this.onModalCancel();
        this.current = 1
        this.getList()
      } catch (e) {
        console.error(e)
      }
    },
    onSquareAudit(e, record) {
      if (this.loading) return;
      this.loading = true;
      let msg = this.$t("member.acct.squareAuditConfirmOpen", record);
      if (!e) {
        msg = this.$t("member.acct.squareAuditConfirmClose", record);
      }
      this.$confirm({
        title: this.$t("member.acct.squareAuditTitle"),
        content: msg,
        onOk: async () => {
          const res = await squareAudit({
            squareAudit: e,
            userId: record.userId,
          });
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            this.loading = false;
            return;
          }
          showMsg({
            flag: FETCH_CODE.SUCCESS.KEY,
            msg: this.$t("showMsg.success.operation"),
          });
          this.loading = false;
          // 刷新列表
          this.getList();
        },
        onCancel: () => {
          this.loading = false;
        },
      });
    },
  }
}
</script>

<style lang="less" scoped>
.modal-title {
  font-weight: bold;
  color: @primary-color;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
